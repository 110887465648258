@import "../../../assets/scss/variable.scss";

.custom-dialog {
  width: 681px;
  max-width: 96%;
  margin: 0 auto;

  .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    overflow-y: hidden;
    padding: 45px 42px;
    margin: 0px;
  }

  .dialog-close {
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      background-color: transparent;
    }
  }

  @media (max-width: 799px) {
    position: absolute !important;
    // z-index: auto !important;

    .MuiDialog-paper {
      top: 0px;
      // top: 210px;
      margin: 0;
      overflow-y: auto;
      // height: 100%;
    }

    .hide-mobilemenu {
      display: none;
    }
  }

  @media (max-width: 639px) {
    .MuiDialog-paper {
      // top: 240px;
      //   top: 195px;
      top: 0px;
      padding: 33px 18px;
    }
  }

  @media (max-width: 539px) {
    .MuiDialog-paper {
      //   top: 206px;
      top: 0px;
    }
  }

  @media (max-width: 439px) {
    .MuiDialog-paper {
      //   top: 185px;
      top: 0px;
    }
  }
}

.login-dialog {
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
  .MuiDialog-paper {
    max-width: 1140px;
    background-position: 50%;
    margin: 0px 12px;
    width: 100%;
    background-image: url("../../../assets/images/login-popup-desktopV2.png");
    border-radius: 8px;
    border: 1px solid $color-Secondary;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1023px) {
      background-image: url("../../../assets/images/login-popup-mobileV2.png");
      background-position: bottom;
      background-size: cover;
    }
  }
  .dialog-close {
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 639px) {
      padding: 0px;
    }
    &:hover {
      background-color: transparent;
    }
  }
  .login-dialogbox {
    text-align: center;
    min-height: 600px;
    @media (max-width: 459px) {
      min-height: 460px;
    }
    .login-title {
      font-family: $Apotek_Comp_Regular !important;
      font-size: 60px;
      text-transform: uppercase;
      line-height: 50px;
      color: $color-White;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 15px;
      @media (max-width: 1023px) {
        font-size: 30px;
        line-height: 35px;
        padding-top: 23px;
        padding-bottom: 7px;
      }
    }
    .login-text {
      font-family: $regulerFont;
      font-size: 22.4px;
      line-height: 27px;
      color: $color-White;
      text-align: center;
      padding-bottom: 18px;
      @media (max-width: 1023px) {
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 15px;
        width: 236px;
        margin: 0 auto;
        img {
          width: 36.5px;
        }
      }
    }
    .login-button {
      max-width: 374px;
      width: 100%;
      margin: 0px auto 17px;
      font-size: 32px;

      color: $color-White;
      border-radius: 15px;
      background: linear-gradient(180deg, #fc4714, #fc7914);
      border-radius: 8px;
      border: 1px solid #ffffff;
      font-family: $Apotek_Comp_Regular !important;
      text-align: center;
      @media (max-width: 1023px) {
        max-width: 252px;
        margin: 0px auto 11px;
        font-size: 20px;
      }
      span {
        font-family: $peckhamPressTrial !important;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .dont-account-text {
      color: #f7f9fb;
    }
    .signup-text {
      font-weight: 600;
      color: $color-Secondary;
      text-decoration: underline;
      cursor: pointer;
    }
    .login-extra-info {
      padding: 33px 0px 123px;
      @media (max-width: 1023px) {
        padding: 25px 0px 40px;
      }
      @media (max-width: 639px) {
        padding: 21px 0px 115px;
      }
      .tag-line {
        font-family: $theStamshonsDemo !important;
        font-size: 32px;
        line-height: 39px;
        color: $color-Secondary;
        padding-bottom: 4px;
        @media (max-width: 639px) {
          font-size: 17px;
          line-height: 21px;
          padding-bottom: 3px;
        }
      }
      .smartb-feature-wrap {
        .smartb-feature {
          font-size: 22px;
          line-height: 26px;
          font-family: $peckhamPressTrial !important;
          color: $color-White;
          @media (max-width: 639px) {
            font-size: 13px;
            line-height: 16px;
          }
          .seperator {
            color: $color-Secondary;
          }
        }
      }
    }
  }
}
.w570 {
  width: 570px !important;
  max-width: 96% !important;
}

.border-radius-chat {
  .MuiDialog-paper {
    border-radius: 8px !important;
  }
}

.blackBook-dialog {
  width: 894px;
  max-width: 96%;
  margin: 0 auto;

  .br-8 {
    border-radius: 8px !important;
  }

  .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    overflow-y: auto;
    // padding: 45px 42px;
    margin: 0px;
  }

  .background-img {
    background-image: url("../../../assets/images/header-img.png");
    object-fit: cover;
  }

  .MuiDialogTitle-root {
    padding: 21px 18px 9px 42px;
    background-color: #e8eaec;

    .custom-dialog-title {
      .dialog-title {
        font-size: 31.36px;
        line-height: 40px;
        font-weight: 400;
        font-family: $primaryFont;
        display: flex;
        justify-content: space-between;

        .title-chip {
          display: flex;
          align-items: center;
          column-gap: 21px;

          .title {
            text-transform: capitalize;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            font-family: $regulerFont !important;
            color: #003764;
          }

          .profile-pic {
            height: 68px;
            width: 68px;
            border-radius: 50%;

            @media (max-width: 560px) {
              height: 50px;
              width: 50px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    @media (max-width: 599px) {
      padding: 21px 12px 9px 18px;
    }
  }

  .dialog-close {
    padding: 0px;

    &:hover {
      background-color: transparent;
    }
  }

  .blackBook-content-wrap {
    padding: 18px 42px 27px;

    @media (max-width: 599px) {
      padding: 9px 18px 27px;
    }

    .blackbook-type-wrap {
      padding-bottom: 21px;

      @media (max-width: 599px) {
        padding-bottom: 18px;
      }

      .blackbook-content {
        span {
          font-size: 16px;
          line-height: 20px;
          font-family: $regulerFont;
        }

        .content-label {
          font-weight: 600;
        }

        .smart-content-label {
          font-weight: 400;
        }

        .content-des {
          font-weight: 400;
        }
      }

      .details {
        @media (max-width: 599px) {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .details-textarea-field {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: $regulerFont;
      padding: 13px 11px;
      border-radius: 8px;
      border-color: #d4d6d8;

      @media (max-width: 799px) {
        width: 96% !important;
        height: 174px !important;
        margin-top: 5px;
      }

      @media (max-width: 539px) {
        font-size: 15px;
      }

      @media (max-width: 439px) {
        width: 91% !important;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .blackbook-btn-wrap {
      text-align: end;
      padding-top: 27px;

      @media (max-width: 599px) {
        padding-top: 18px;
      }

      .blackbook-button {
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        font-family: $regulerFont;
        color: $color-White;
        background-color: $color-Accent-1;

        .MuiButton-label {
          text-transform: initial;
        }

        @media (max-width: 599px) {
          width: 100%;
        }
      }
    }

    .close-btn {
      text-align: end;
    }

    .send-invite-txt {
      text-align: center;
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 600;
      font-family: $regulerFont !important;
      color: #191919;

      @media (max-width: 560px) {
        font-size: 18px;
      }
    }

    .mt-0 {
      margin-top: 0px !important;
    }

    .share-txt {
      text-align: center;
      margin-top: 11px;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      font-family: $regulerFont !important;
      color: #191919;

      @media (max-width: 560px) {
        font-size: 15px;
      }
    }

    .share-option {
      margin-top: 19.3px !important;
      max-width: 327px;
      // max-width: 551px;
      width: 100%;
      margin: 0 auto;
      column-gap: 34px;

      // @media (max-width: 1048px) {
      //   justify-content: center;
      //   max-width: 296px;
      //   row-gap: 11px;
      //   margin-top: 12px;
      // }

      .copy-link {
        cursor: pointer;
      }

      .react-share__ShareButton {
        width: 32px;
        height: 32px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .social-icon {
        cursor: pointer;
      }
    }

    .comp-modal-details {
      margin-top: 21px !important;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;

      .user-comp-detail {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;

        &:first-child {
          margin-top: 0px;
        }

        .left {
          // width: 51%;
          width: 70%;
          color: #191919;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          font-family: $regulerFont;

          @media (max-width: 400px) {
            font-size: 15px;
            width: 60%;
          }
        }

        .right {
          width: 30%;
          // width: 49%;
          color: #191919;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: $regulerFont;

          @media (max-width: 400px) {
            font-size: 15px;
            width: 40%;
          }
        }
      }
    }

    .done-img-sec {
      text-align: center;

      .done-img {
        height: 95px;
        width: 95px;
        object-fit: cover;

        @media (max-width: 560px) {
          height: 85px;
          width: 85px;
        }
      }
    }

    .payment-txt-sec {
      margin-top: 16px;
      text-align: center;

      .title {
        font-family: $regulerFont;
        font-size: 22.4px;
        line-height: 31.36px;
        color: #191919;
        font-weight: 600;
      }

      .des {
        margin-top: 11px;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 22.4px;
        color: #191919;
        font-weight: 400;
      }
    }

    .skip-now-btn-sec {
      margin-top: 59px;
      width: 100%;

      .skip-now-btn {
        padding: 16px 0px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        font-family: $regulerFont;
        color: $color-Accent-1;
        border: 1px solid $color-Accent-1;

        .MuiButton-label {
          text-transform: initial;
        }

        @media (max-width: 599px) {
          width: 100%;
        }
      }

      .view-tips-btn {
        padding: 16px 0px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        font-family: $regulerFont;
        background-color: $color-Accent-1;
        color: $color-White;
        text-transform: capitalize;
      }
    }

    .join-now-btn-sec {
      margin-top: 59px;
      width: 100%;

      .join-now-btn {
        padding: 16px 0px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        font-family: $regulerFont;
        background-color: $color-Accent-1;
        color: $color-White;

        .MuiButton-label {
          text-transform: initial;
        }

        @media (max-width: 599px) {
          width: 100%;
        }
      }
    }
  }

  .smartbook-content-wrap {
    padding: 18px 42px 27px;

    @media (max-width: 599px) {
      padding: 9px 18px 27px;
    }

    .smartbook-type-wrap {
      padding-bottom: 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 599px) {
        padding-bottom: 18px;
      }

      .blackbook-content {
        span {
          font-size: 16px;
          line-height: 20px;
          font-family: $regulerFont;
        }

        .content-label {
          font-weight: 600;
        }

        .smart-content-label {
          font-weight: 400;
        }

        .content-des {
          font-weight: 400;
        }
      }

      .details {
        @media (max-width: 599px) {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .details-textarea-field {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: $regulerFont;
      padding: 13px 11px;
      border-radius: 8px;
      border-color: #d4d6d8;

      @media (max-width: 799px) {
        width: 96% !important;
        height: 174px !important;
        margin-top: 5px;
      }

      @media (max-width: 539px) {
        font-size: 15px;
      }

      @media (max-width: 439px) {
        width: 91% !important;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .blackbook-btn-wrap {
      text-align: end;
      padding-top: 27px;

      @media (max-width: 599px) {
        padding-top: 18px;
      }

      .blackbook-button {
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        font-family: $regulerFont;
        color: $color-White;
        background-color: $color-Accent-1;

        .MuiButton-label {
          text-transform: initial;
        }

        @media (max-width: 599px) {
          width: 100%;
        }
      }
    }
  }

  .chat-content-wrap {
    .d-flex {
      display: flex !important;
    }

    .messages-sec {
      height: 354px;
      //   display: flex;
      overflow-y: auto;
      padding: 0px 18px 0px 18px;

      .messages-container {
        flex: 1;
        // padding: 10px;
        max-height: max-content;
        align-self: end;

        &.user-message {
          background-color: #4caf50;
          color: $color-White;
          align-self: flex-end;
        }

        &.bot-message {
          background-color: #ddd;
          color: $color-Black;
          align-self: flex-start;
        }

        .day-devider {
          display: flex;
          align-items: center;
          column-gap: 13px;
          margin-top: 14px;

          .devider {
            height: 1px;
            width: 100%;
            background-color: #e7e9ec;
          }

          .day {
            max-width: 110px;
            width: 100%;
            font-size: 11.42px;
            font-weight: 400;
            line-height: 15px;
            font-family: $regulerFont;
            border-color: #989898;

            @media (max-width: 460px) {
              font-size: 10.7px;
            }
          }
        }

        .message {
          margin: 5px;
          // padding: 8px;
          border-radius: 5px;

          .message-sec {
            display: flex;
            align-items: center;
            column-gap: 9px;

            // overflow-y: scroll;
            .profile-pic {
              height: 45px;
              width: 45px;
              border-radius: 50%;

              // @media (max-width: 1300px) {
              //   right: 10px;
              //   top: 20%;
              // }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .msg-time {
              margin-top: 18px;

              .align-end {
                text-align: end !important;
              }

              .time {
                margin-top: 3px;
                font-size: 11.42px;
                font-weight: 400;
                line-height: 15px;
                font-family: $regulerFont;
                border-color: #989898;
              }

              .chat {
                font-size: 16px;
                font-weight: 400;
                line-height: 22.4px;
                font-family: $regulerFont;
                border-color: #191919;
              }

              .user-message-txt {
                max-width: 345px;
                // width: 100%;
                border: 1px solid #d4d6d8;
                border-top-right-radius: 8px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                padding: 11px;
                overflow-wrap: break-word;
              }

              .bot-message-txt {
                max-width: 345px;
                // width: 100%;
                border: 1px solid #d4d6d8;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                padding: 11px;
                overflow-wrap: break-word;

                @media (max-width: 460px) {
                  max-width: 251px;
                }
              }
            }
          }

          .bot-message-sec {
            flex-direction: row-reverse !important;
            justify-content: end !important;
          }
        }
      }
    }

    .send-box {
      padding: 18px;
      background-color: #d4d6d8;
      display: flex;
      align-items: center;
      column-gap: 10px;

      .text-field {
        width: 100%;

        .MuiInputBase-input {
          padding: 10px 10px;
          // max-width: 439px;
          // width: 100%;
          min-height: 25px;
          font-family: $regulerFont;
          font-size: 16px;
          line-height: 20px;
        }

        .MuiInputBase-root {
          background-color: $color-White !important;
          border-radius: 8px;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          display: none;
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: #4455c7;
            border-width: 1;
          }
        }

        .MuiOutlinedInput-adornedEnd {
          justify-content: space-between;
        }
      }

      .send-btn {
        max-width: 86px;
        width: 100%;
        padding: 11px 25px 12px 25px;
        background-color: #4455c7;
        color: $color-White;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;
        // @media (max-width: 579px) {
        //   font-size: 11.42px;
        // }
      }
    }
  }

  .tipper-rating-wrap {
    padding: 21px 42px 27px 42px;

    @media (max-width: 600px) {
      padding: 21px 18px 18px 18px;
    }

    .tipper-rating-modal-sec {
      .avatar-sec {
        height: 73px;
        width: 73px;
        border-radius: 50%;

        .avatar {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .tipper-name {
        margin-top: 6px;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 21px;
        color: #4455c7;
        font-weight: 600;
      }

      .rate-sec {
        margin-top: 17px;

        .title {
          font-family: $regulerFont;
          font-size: 11.42px;
          line-height: 15px;
          color: #191919;
          font-weight: 600;
        }

        .rating-sec {
          margin-top: 5px;
          // padding: 12px 21px;
          padding: 6px 21px 2px 21px;
          border: 1px solid #d4d6d8;
          border-radius: 8px;

          .rating {
            // margin-left: 18px;
            color: #1a73e8;

            .MuiRating-icon {
              font-size: 32px;
              margin-right: 19px;
            }
          }
        }
      }

      .submit-btn-wrap {
        text-align: end;
        padding-top: 27px;

        @media (max-width: 599px) {
          padding-top: 18px;
        }

        .submit-button {
          padding: 12px 24px;
          border-radius: 8px;
          font-size: 16px;
          line-height: 20px;
          font-family: $regulerFont;
          color: $color-White;
          background-color: $color-Accent-1;

          .MuiButton-label {
            text-transform: initial;
          }

          @media (max-width: 599px) {
            width: 100%;
          }
        }
      }
    }
  }
}

.social-dialog {
  width: 570px !important;

  .social-padding {
    padding: 18px 18px 27px 18px;

    @media (max-width: 599px) {
      padding: 12px 12px 18px;
    }

    .share-icon-option {
      max-width: 312px;
      width: 100%;
      margin: 21.3px auto 0px;

      @media (max-width: 799px) {
        margin: 19px auto 0px;
      }

      .social-option-box {
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 26px;
        row-gap: 27.3px;

        @media (max-width: 799px) {
          row-gap: 18px;
        }

        .option {
          text-align: center;
        }

        .option-name {
          font-family: $regulerFont;
          font-size: 11.42px;
          line-height: 15px;
          color: #5d5d5d;

          @media (max-width: 560px) {
            font-size: 11.42px;
          }
        }
      }
    }

    .skip-now-btn-sec {
      margin-top: 33.3px;

      @media (max-width: 799px) {
        margin-top: 26.8px;
      }
    }
  }
}

.supported-team-dialog {
  width: 657px !important;
  .blackBook-content-wrap {
    .close-btn {
      position: absolute;
      top: 11px;
      right: 11px;
      @media (max-width: 599px) {
        top: 6px;
        right: 6px;
      }
    }
    .header-label {
      font-size: 22.4px;
      line-height: 27px;
      font-family: $regulerFont;
      font-weight: 400;
      margin-bottom: 18px;
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 22px;
      }
    }
    .textfield-text {
      font-size: 16px;
      line-height: 20px;
      font-family: $regulerFont;
      font-weight: 400;
      margin-bottom: 13px;
      .danger {
        color: red;
      }
      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 6px;
      }
    }
    .select__control {
      box-shadow: none;
      min-height: 45px;
      padding: 5px 0px;
      margin: 0px;
      border-radius: 8px;

      @media (max-width: 490px) {
        margin-top: 5px;
      }
    }

    .select__control,
    .react-select__control {
      &.select__control--is-disabled {
        // border-color: $border-color;
        .select__indicator-separator {
          //   background-color: $border-color;
        }
      }

      // &.select__control--is-focused,
      // &.react-select__control--is-focused {

      // }

      &.select__control--menu-is-open {
        box-shadow: none;
        // border: none;
      }

      .select__indicator svg {
        cursor: pointer;
      }

      .select__indicator-separator {
        display: none;
      }

      .select__single-value {
        // border: none;
        font-size: 16px;
        line-height: 20px;
        padding-left: 10px;
        outline: none;
        cursor: pointer;
        margin: 0px;
        font-family: "Inter", sans-serif;
      }

      .select__value-container {
        // border: none;
        padding: 0px;
        margin: 0px;

        .select__input-container {
          font-size: 16px;
          font-family: "Inter", sans-serif;
          margin-left: 10px;
        }
      }

      .select__placeholder {
        font-size: 16px;
        line-height: 20px;
        padding-left: 10px;
        cursor: pointer;
        font-family: $regulerFont;
      }
    }

    .select__menu {
      margin: 0px;
      border-radius: 0px;
      padding: 0px;
      z-index: 999;
    }

    .select__menu-list {
      padding: 0px;
    }

    // Select Menu
    .select__menu,
    .react-select__menu {
      .select__menu-list,
      .react-select__menu-list {
        .select__option,
        .react-select__option {
          cursor: pointer;
          font-size: 16px;
          color: $color-Black;
          font-family: $regulerFont;
          line-height: 19px;
          padding: 6px;

          &.select__option--is-focused {
            background-color: $color-grey;
            color: #000;
          }

          &.select__option--is-selected {
            background-color: $color-grey;
            color: $color-Black;
          }
        }
      }

      .select__menu-list,
      .react-select__menu-list {
        .select__group {
          .select__group-heading {
            margin-bottom: 0.5rem;
            color: green;
            font-weight: bolder;
            font-size: inherit;
          }
        }
      }
    }

    .join-now-btn-sec {
      margin-top: 15px;
      @media (max-width: 599px) {
        margin-top: 22px;
      }
      .join-now-btn {
        padding: 12px 0px;
      }
    }
  }
  .social-padding {
    padding: 30px;
    @media (max-width: 599px) {
      padding: 23px 18px;
    }
  }
}
.uploadimg-dialog {
  width: 680px;
  max-width: 96%;
  margin: 0 auto;

  .br-8 {
    border-radius: 8px !important;
  }

  .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    overflow-y: auto;
    // padding: 45px 42px;
    margin: 0px;
  }

  .background-img {
    background-image: url("../../../assets/images/header-img.png");
    object-fit: cover;
  }

  .MuiDialogTitle-root {
    padding: 21px 18px 9px 18px;
    border-bottom: 1px solid $color-grey;

    .custom-dialog-title {
      .dialog-title {
        font-size: 22.4px;
        line-height: 28px;
        font-weight: 400;
        font-family: $primaryFont;
        display: flex;
        justify-content: center;

        .title-chip {
          display: flex;
          align-items: center;
          column-gap: 21px;

          .title {
            text-transform: capitalize;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            font-family: $regulerFont !important;
            color: #003764;
          }

          .profile-pic {
            height: 68px;
            width: 68px;
            border-radius: 50%;

            @media (max-width: 560px) {
              height: 50px;
              width: 50px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    @media (max-width: 599px) {
      padding: 21px 12px 9px 18px;
    }
  }

  .dialog-close {
    padding: 0px;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .uploadimg-content-wrap {
    .dropzone-wrap {
      padding: 72px 0px;

      @media (max-width: 799px) {
        padding: 24px 18px;
      }

      cursor: pointer;
    }

    .modal-btn-wrap {
      padding: 30px 42px;
      border-top: 1px solid $color-grey;

      @media (max-width: 799px) {
        padding: 22px 18px;
      }

      .modal-btn {
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        border: 1px solid $color-Accent-1;
        border-radius: 8px;
        letter-spacing: 0px;
        background-color: transparent;
        color: $color-Accent-1;
        width: 100%;
        padding: 12px 0;
        text-transform: none;
      }
    }
  }
}

.file-uploader {
  max-width: 524px;
  width: 100%;
  margin: 0px auto;

  .dropzone {
    min-height: 330px;
    border: dashed 1px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 799px) {
      min-height: 230px;
    }

    p {
      margin-bottom: 0;
      margin-top: 0;
    }

    .dropzone-label {
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 600;
      color: $color-Accent-1;
      text-align: center;
    }
  }
}
