@import "src/assets/scss/variable.scss";

.v3-header-menu-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .v3-header-menu-list {
    display: flex;
    justify-content: center;
    align-items: center;

    .v3-header-menu-btn {
      cursor: pointer;
      font-family: $regulerFont;
      padding: 9px 12px;
      line-height: 25px;
      font-size: 20.8px;
      font-weight: 600;
      color: $color-Primary;
      margin-right: 18px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 8px;

      .logo-img-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;

        a {
          height: 30px;
        }


        img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
          transition: all 0.3s ease;
        }
      }

      img {
        height: 30px !important;
      }

      &:last-child() {
        margin-right: 0;
      }

      &.active {
        background: $color-Light-grey-4;
      }

      &.inactive {
        background: transparent;
      }

      &:hover {
        background: $color-Light-grey-4;
      }

      @media (max-width: 1600px) {
        padding: 9px 9px;
        line-height: 16px;
        font-size: 14px;
        margin-right: 10px;

        .logo-img-header {
          img {
            max-height: 30px;
          }
        }
      }

      @media (max-width: 1300px) {
        margin-right: 5px;

        .logo-img-header {
          img {
            max-height: 25px;
          }
        }
      }

      @media (max-width: 1024px) {
        padding: 8px 8px;
        line-height: 14px;
        font-size: 11.42px;

        .logo-img-header {
          img {
            max-height: 20px;
          }
        }
      }
    }

    .v3-header-menu-arrow-icon {
      margin-left: 3px;
      padding: 3px 0px;

      @media (max-width: 1300px) {
        padding: 2px 0px;
      }
    }
  }

  .v3-header-subscribe-btn {
    font-family: $regulerFont;
    font-weight: 400;
    font-size: 16px;
    background: $color-Primary;
    color: $color-White;
    border-radius: 19px;
    padding: 9px 12px;
    text-transform: inherit;
    line-height: 22.4px;

    &:hover {
      background: $color-Primary;
      color: $color-White;
      opacity: 0.8;
    }

    @media (max-width: 1600px) {
      padding: 9px 9px;
      line-height: 16px;
      font-size: 14px;
      margin-right: 10px;
    }

    @media (max-width: 1300px) {
      margin-right: 5px;
    }

    @media (max-width: 1024px) {
      padding: 8px 8px;
      line-height: 14px;
      font-size: 11.42px;
      margin-right: 10px;
    }
  }

  .smart-play-logo-wrap {
    &:hover {
      background: $color-Light-grey-4;
    }
  }
}

.v3-header-sub-menu-wrapper {
  display: grid;
  grid-template-rows: repeat(8, auto);
  grid-auto-flow: column;
}

.v3-header-sub-menu-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .v3-header-sub-menu-btn {
    font-family: $regulerFont;
    padding: 9px 9px;
    line-height: 22.4px;
    font-size: 16px;
    font-weight: 400;
    color: $color-Primary;
    margin-right: 9px;
    border-radius: 6px;
    width: 252px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;

    &.active {
      background: $color-Light-grey-4;
    }

    &.inactive {
      background: transparent;
    }

    &:hover {
      background: $color-Light-grey-4;
    }
  }

  .v3-header-sub-menu-arrow-icon {
    position: absolute;
    right: 6px;
    padding: 3px 0px;
  }
}

.custom-sub-menu-tooltip {
  margin-top: 0 !important;
  margin-left: 15px !important;
}