@import "../../../assets/scss/variable.scss";

.install-apk-layout {
    .content {
        padding: 0px;

        @media (max-width:769px) {
            padding: 0px 12px;
        }
    }
}

.install-wrapper {
    background-image: url("../../../assets/images/install-apk.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.install-apk-container {
    max-width: 1374px;
    margin: 0px auto;
    width: 100%;

    .apk-container {
        padding: 27px 12px 71px;
    }
}

.install-info-box {
    background-color: $color-Light-grey;
    padding: 18px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    column-gap: 15px;

    @media (max-width:799px) {
        align-items: flex-start;
    }

    svg {
        min-width: 31.59px;
        min-height: 31.59px;

        @media (max-width:799px) {
            min-width: 24.47px;
            min-height: 24.47px;
        }
    }

    .info-text {
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Black;

        @media (max-width:799px) {
            font-size: 12px;
            line-height: 17px;
        }
    }
}

.install-heading {
    font-size: 61.47px;
    line-height: 61.47px;
    font-weight: 400;
    font-family: $Apotek_Comp_Regular !important;
    color: $color-White;
    max-width: 760px;
    text-transform: uppercase;
    margin-top: 27px;
    text-align: left;

    @media (max-width:799px) {
        font-size: 43.9px;
        line-height: 43px;
    }

    .highlight {
        color: $color-Secondary;
        font-family: $Apotek_Comp_Regular !important;
    }
}

.follow-steps {
    font-size: 61.47px;
    line-height: 61.47px;
    font-weight: 400;
    font-family: $Apotek_Comp_Regular !important;
    color: $color-White;
    max-width: 760px;
    text-transform: uppercase;
    text-align: left;

    @media (max-width:799px) {
        font-size: 43.9px;
        line-height: 43px;
    }
}

.steps-box-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1024px) {
        flex-direction: column;
        row-gap: 36px;
    }
}

.steps-container {
    display: flex;
    flex-direction: column;
    column-gap: 36px;
    row-gap: 27px;
    width: calc(100% - 600px);
    margin-top: 27px;

    @media (max-width:1024px) {
        width: 100%;
    }

    @media (max-width:799px) {
        margin-top: 15px;
    }
}

.step-box {
    display: flex;
    align-items: flex-start;
    column-gap: 12px;

    svg {
        min-width: 26.75px;
        min-height: 35.33px;
        margin-top: 15px;

        @media (max-width:799px) {
            min-width: 17.49px;
            min-height: 23.11px;
            margin-top: 5px;
        }
    }

    .step-text {

        .step-number {
            font-size: 43.9px;
            line-height: 53px;
            font-weight: 400;
            font-family: $Apotek_Comp_Regular !important;
            color: $color-White;
            text-transform: uppercase;

            @media (max-width:799px) {
                font-size: 31.36px;
                line-height: 43.9px;
            }

        }


    }
}

.step-desc {
    font-size: 18px;
    line-height: 22px;
    font-family: $regulerFont;
    font-weight: 400;
    color: $color-White;

    @media (max-width:799px) {
        font-size: 16px;
        line-height: 22.4px;
    }
}

.apps-box {
    background: #fff;
    color: #000;
    border-radius: 12px;
    width: 563px;
    border: 3px solid $color-Secondary;

    @media (max-width:1024px) {
        width: 100%;
    }

    .MuiCardContent-root {
        @media (max-width:799px) {
            padding: 12px;
        }
    }
}

.app-link-box {

    &:not(:last-child) {
        margin-bottom: 27px;
    }


    .app-logo-wrap {
        height: 45px;

        img {
            height: 100%;
        }
    }

    .app-desc {
        font-size: 30px;
        line-height: 36px;
        font-weight: 400;
        font-family: $Apotek_Comp_Regular !important;
        color: $color-Primary;
        margin-top: 9px;
    }

    .app-link-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 9px;

        @media (max-width:659px) {
            flex-direction: column;
            row-gap: 18px;
            align-items: flex-start;
        }

        .apk-link {
            display: flex;
            align-items: center;
            column-gap: 10px;
            padding: 9px 0px;
            background-color: $color-Light-grey;
            border: 1.5px solid $color-Accent-1;
            border-radius: 8px;
            justify-content: center;
            min-width: 355px;

            @media (max-width:659px) {
                min-width: auto;
                padding: 9px;
            }

            a {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                font-family: $regulerFont;
                letter-spacing: 0px;
                color: $color-Accent-1;
                text-decoration: underline;
            }
        }
    }
}